import RegistrationsModel from './components/RegistrationsModel'
import { useState, useCallback, useEffect, useMemo } from 'react'
import s from './index.module.css'
import useCustomer from '@shopify/customer/use-customer'
import registrationsTrack from './RegistrationsTrack'
import { RegistrationsStatus, getAppName } from './const'
import { MULPASS_URL } from '@shopify/const'
import { useRouter } from 'next/router'
import { useUI } from '@components/ui/context'
import Cookies from 'js-cookie'
import { getQuery } from '@lib/utils/cookies'

const COUNTRY = {
  us: 'US',
  ca: 'CA',
  'eu-de': 'DE',
  'eu-en': 'AT',
  uk: 'GB',
  nl: 'LU',
}

const Registrations = ({
  registrations,
  showRegistrationsPopup,
  path,
  urlPathname,
  registrationsModalMetafields,
}) => {
  const { data: customer } = useCustomer()
  const { locale, pathname } = useRouter()
  const email = useMemo(() => (customer ? customer?.email : ''), [customer])
  const [showRegistrationsPop, setShowRegistrationsPop] = useState(false)
  const { setRegistrationsPopup, leftBarShow } = useUI()

  const handleRegistrationsModelClose = useCallback(
    (status) => {
      switch (status) {
        case RegistrationsStatus.QUICK_LOGIN:
          registrationsTrack.closeTrack({
            position: '',
            page_group: 'login_pop',
            button_name: 'close',
          })
          break
        case RegistrationsStatus.REMAINDER:
          registrationsTrack.closeTrack({
            position: '',
            page_group: 'reg_sub_pop',
            button_name: 'close',
          })
          break
        case RegistrationsStatus.MAIN:
          registrationsTrack.closeTrack({
            position: '',
            page_group: 'reg_sub_pop',
            button_name: 'close',
          })
          break
        case RegistrationsStatus.PASSWORD_LOGIN:
          registrationsTrack.closeTrack({
            position: '',
            page_group: 'login_pop',
            button_name: 'close',
          })
          break
        case RegistrationsStatus.COUPONS:
          registrationsTrack.closeTrack({
            position: 'success',
            page_group: 'reg_sub_pop',
            button_name: 'close',
          })

          const hostUrl = window.location.host.split('.')
          const domain = window.location.host.includes('localhost')
            ? 'localhost'
            : `.${hostUrl[1]}.${hostUrl[2]}`
          const b_auth_token = localStorage.getItem('b_auth_token')
          const passportThemeLoginUrl = Cookies.get('passport-theme-url')
          passportThemeLoginUrl &&
            Cookies.remove('passport-theme-url', {
              domain,
              expires: 30,
            })
          window.location.href = `${MULPASS_URL}/theme-login?app=${getAppName(
            locale
          )}&t=${b_auth_token}&redirect=${location.href}`
          break
        default:
          break
      }
      setRegistrationsPopup(false)
      setShowRegistrationsPop(false)
    },
    [locale, setRegistrationsPopup]
  )

  const showRegistrationsModal = useMemo(() => {
    let result = false
    if (
      // 系列页
      registrationsModalMetafields.collections_handles &&
      path?.includes('collections')
    ) {
      for (const collection_handle of registrationsModalMetafields.collections_handles) {
        result = !!collection_handle && path.includes(collection_handle)
        if (result) break
      }
    }
    // blog页
    if (registrationsModalMetafields.blogs_handles && path?.includes('blogs')) {
      for (const blog_handle of registrationsModalMetafields.blogs_handles) {
        result = !!blog_handle && path.includes(blog_handle)
        if (result) break
      }
    }
    // page页
    if (
      registrationsModalMetafields.pages_handles &&
      pathname === '/[...pages]'
    ) {
      for (const pages_handle of registrationsModalMetafields.pages_handles) {
        result = !!pages_handle && path.includes(pages_handle)
        if (result) break
      }
    }
    // 产品
    if (registrationsModalMetafields.handles && path?.includes('products')) {
      for (const handle of registrationsModalMetafields.handles) {
        result = !!handle && path.includes(handle)
        if (result) break
      }
    }
    // 首页
    if (
      (registrationsModalMetafields?.chgIndex && path === '/') ||
      (registrationsModalMetafields?.ppsIndex && path === '/anker-solix')
    ) {
      result = true
    }
    return result
  }, [registrationsModalMetafields, path, pathname])

  // 设置Cookie
  const setItemWithExpiry = useCallback((key, value, timestamp, domain) => {
    const now = new Date()
    const item = {
      value: value,
      expiry: now.getTime() + timestamp, // 设置过期时间
      timestamp,
    }
    Cookies.set(key, item, {
      domain,
      expires: 30,
    })
  }, [])

  // 获取Cookie
  const getItemWithExpiry = useCallback((key, domain) => {
    const itemString = Cookies.get(key)
    if (!itemString) {
      return null
    }
    const item = JSON.parse(itemString)
    const now = new Date()

    // 检查是否过期
    if (now.getTime() > item.expiry) {
      Cookies.remove(key, {
        domain,
        expires: 30,
      })
      return null
    }
    return item
  }, [])

  // 设置是否弹窗标识
  useEffect(() => {
    const hostUrl = window.location.host.split('.')
    const domain = window.location.host.includes('localhost')
      ? 'localhost'
      : `.${hostUrl[1]}.${hostUrl[2]}`

    const registrations_pop_up = getItemWithExpiry(
      `${COUNTRY?.[locale]}_registrations_pop_up`,
      domain
    )

    // if (showRegistrationsPopup) {
    //   registrationsTrack.pvTrack()
    // }

    // 参数符合则强制弹框
    let popRegistrations
    const query = getQuery()
    const handleItem = registrationsModalMetafields?.condition_display?.find(
      (item) => urlPathname === item?.handle
    )
    popRegistrations =
      query[handleItem?.params] &&
      query[handleItem?.params] === handleItem?.value

    if (!registrations_pop_up || popRegistrations) {
      const timer = setTimeout(() => {
        if ((!email && showRegistrationsModal) || popRegistrations) {
          registrationsTrack.pvTrack()
          setShowRegistrationsPop(true)
          setItemWithExpiry(
            `${COUNTRY?.[locale]}_registrations_pop_up`,
            true,
            14 * 24 * 60 * 60 * 1000,
            domain
          )
        }
        clearTimeout(timer)
      }, 5000)
    }

    // 存在用户直接刷新页面的情况，重定向到passport，带上登录态
    const passportThemeLoginUrl = Cookies.get('passport-theme-url')
    if (passportThemeLoginUrl) {
      Cookies.remove('passport-theme-url', {
        domain,
        expires: 30,
      })
      window.location.href = passportThemeLoginUrl
    }
  }, [
    email,
    showRegistrationsPopup,
    locale,
    getItemWithExpiry,
    setItemWithExpiry,
    showRegistrationsModal,
  ])

  const registrationsModalStatus = useMemo(() => {
    return showRegistrationsPopup || (!email && showRegistrationsPop)
  }, [email, showRegistrationsPopup, showRegistrationsPop])

  useEffect(() => {
    if (registrationsModalStatus) {
      document.querySelector('html').classList.add('fw-hidden')
    } else if (!leftBarShow) {
      document.querySelector('html').classList.remove('fw-hidden')
    }
  }, [registrationsModalStatus, leftBarShow])

  return (
    registrationsModalStatus && (
      <>
        <div aria-label="mask" className={s.rsm_close_mask}></div>
        <RegistrationsModel
          onClose={handleRegistrationsModelClose}
          gs={s}
          locale={locale}
          data={registrations}
        />
      </>
    )
  )
}

export default Registrations
